import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkTypography data-test-details-table-country-code>\n  {{this.countryName}}\n</AkTypography>", {"contents":"<AkTypography data-test-details-table-country-code>\n  {{this.countryName}}\n</AkTypography>","moduleName":"irene/components/app-monitoring/details-table/country/index.hbs","parseOptions":{"srcName":"irene/components/app-monitoring/details-table/country/index.hbs"}});
import Component from '@glimmer/component';

import { COUNTRY_NAMES_MAP } from 'irene/utils/constants';
import AmAppRecordModel from 'irene/models/am-app-record';

interface AppMonitoringDetailsTableCountrySignature {
  Args: {
    amAppRecord: AmAppRecordModel;
  };
}

export default class AppMonitoringDetailsTableCountryComponent extends Component<AppMonitoringDetailsTableCountrySignature> {
  get amAppRecord() {
    return this.args.amAppRecord;
  }

  get storeInstance() {
    return this.amAppRecord.get('amAppStoreInstance');
  }

  get countryName() {
    const countryCode = this.storeInstance
      ?.get('countryCode')
      ?.trim()
      .toUpperCase() as keyof typeof COUNTRY_NAMES_MAP;

    return COUNTRY_NAMES_MAP[countryCode] || countryCode || '-';
  }
}
