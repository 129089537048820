import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div data-test-history-table-store-version>\n  {{#if @amAppSync.amApp.latestAmAppVersion.comparableVersion}}\n    <AkTypography>\n      {{@amAppSync.amApp.latestAmAppVersion.comparableVersion}}\n    </AkTypography>\n  {{else}}\n    <AppMonitoring::Status\n      @condition='{{am-status-condition this.amApp}}'\n      @label='{{am-status-text this.amApp}}'\n    />\n  {{/if}}\n</div>", {"contents":"<div data-test-history-table-store-version>\n  {{#if @amAppSync.amApp.latestAmAppVersion.comparableVersion}}\n    <AkTypography>\n      {{@amAppSync.amApp.latestAmAppVersion.comparableVersion}}\n    </AkTypography>\n  {{else}}\n    <AppMonitoring::Status\n      @condition='{{am-status-condition this.amApp}}'\n      @label='{{am-status-text this.amApp}}'\n    />\n  {{/if}}\n</div>","moduleName":"irene/components/app-monitoring/history-table/store-version/index.hbs","parseOptions":{"srcName":"irene/components/app-monitoring/history-table/store-version/index.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import Store from '@ember-data/store';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import AmAppModel from 'irene/models/am-app';
import AmAppSyncModel from 'irene/models/am-app-sync';

interface AppMonitoringTableStoreVersionSignature {
  Args: {
    amAppSync: AmAppSyncModel;
  };
}

export default class AppMonitoringHistoryTableStoreVersionComponent extends Component<AppMonitoringTableStoreVersionSignature> {
  @service declare store: Store;

  @tracked amApp: AmAppModel | null = null;

  constructor(
    owner: unknown,
    args: AppMonitoringTableStoreVersionSignature['Args']
  ) {
    super(owner, args);
    this.fetchAmApp.perform();
  }

  fetchAmApp = task(async () => {
    const amAppId = this.args.amAppSync.amApp.get('id');

    if (amAppId) {
      const amApp = await this.store.findRecord('am-app', amAppId);
      this.amApp = amApp;
    }
  });
}
