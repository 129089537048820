import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  ...attributes\n  @direction='column'\n  @alignItems='center'\n  local-class='loading'\n  class='pt-7'\n  data-test-am-loading-container\n>\n  <AkSvg::EmptyLoadingList local-class='loading-state-vector' data-test-am-loading-illustration />\n\n  <AkStack @direction='column' @alignItems='center' @spacing='1.5' class='mt-2'>\n    <AkLoader @size={{19}} data-test-am-loading-spinner />\n\n    {{#if @loadingText}}\n      <AkTypography local-class='loading-text'>\n        {{@loadingText}}\n      </AkTypography>\n    {{/if}}\n  </AkStack>\n</AkStack>", {"contents":"<AkStack\n  ...attributes\n  @direction='column'\n  @alignItems='center'\n  local-class='loading'\n  class='pt-7'\n  data-test-am-loading-container\n>\n  <AkSvg::EmptyLoadingList local-class='loading-state-vector' data-test-am-loading-illustration />\n\n  <AkStack @direction='column' @alignItems='center' @spacing='1.5' class='mt-2'>\n    <AkLoader @size={{19}} data-test-am-loading-spinner />\n\n    {{#if @loadingText}}\n      <AkTypography local-class='loading-text'>\n        {{@loadingText}}\n      </AkTypography>\n    {{/if}}\n  </AkStack>\n</AkStack>","moduleName":"irene/components/app-monitoring/loading/index.hbs","parseOptions":{"srcName":"irene/components/app-monitoring/loading/index.hbs"}});
import Component from '@glimmer/component';

interface AppMonitoringLoadingSignature {
  Element: HTMLElement;
  Args: {
    loadingText?: string;
  };
}

export default class AppMonitoringLoadingComponent extends Component<AppMonitoringLoadingSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AppMonitoring::Loading': typeof AppMonitoringLoadingComponent;
  }
}
